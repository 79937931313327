<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('lotto_game.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('lotto_game.create_head') }}</v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col cols="12" xs="6" md="6" xl="6">
                <v-text-field v-model="formData.title" :label="$t('lotto_game.activity')" outlined dense
                  disabled></v-text-field>
              </v-col>

              <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                <v-text-field v-model="formData.round" :rules="formRule.round" :label="$t('lotto_game.header2')"
                  type="date" :min="minDate" outlined dense></v-text-field>
              </v-col>

              <v-row class="ma-0 d-flex align-baseline">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeStart" :rules="formRule.timeStart" :label="$t('lotto_game.header3')"
                    type="date" :min="minDate" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <date-picker-input ref="timeHourStart" :time="timeHourStart" @setValue="timeHourStart = $event"
                    :label="$t('lotto_game.header3_2')" :showTime="true" />
                </v-col>
              </v-row>

              <v-row class="ma-0 d-flex align-baseline">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeEnd" :rules="formRule.timeEnd" :label="$t('lotto_game.header4')"
                    type="date" :min="minDate" outlined dense></v-text-field></v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <date-picker-input ref="timeHourEnd" :time="timeHourEnd" @setValue="timeHourEnd = $event"
                    :label="$t('lotto_game.header4_2')" :showTime="true" />
                </v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeBetUse" :items="rewardTypes" item-text="name" item-value="value"
                    :label="$t('lotto_game.bet_type')" outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.betUse" :label="$t('lotto_game.bet_amount')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <!-- Reward type and amount Input -->
          <v-col>
            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('lotto_game.reward3_head') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeReward" :items="rewardTypes"
                    :rules="[v => !!v || this.$t('lotto_game.reward_type')]" :label="$t('lotto_game.reward_type')"
                    outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.rewardLastThreeDigits" :rules="formRule.rewardAmount"
                    :label="$t('lotto_game.reward_amount')" type="number" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.multiplyTurnOverThreeDigits" :rules="formRule.multiplyAmount"
                    :label="$t('lotto_game.reward_multiple')" type="number" outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('lotto_game.reward2_head') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeReward" :items="rewardTypes"
                    :rules="[v => !!v || this.$t('lotto_game.reward_type')]" :label="$t('lotto_game.reward_type')"
                    outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.rewardLastTwoDigits" :rules="formRule.rewardAmount"
                    :label="$t('lotto_game.reward_amount')" type="number" outlined dense></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.multiplyTurnOverTwoDigits" :rules="formRule.multiplyAmount"
                    :label="$t('lotto_game.reward_multiple')" type="number" outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'predict-lotto' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('lotto_game.create') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'

export default {
  components: {
    StaffLimitAccessPage,
    DatePickerInput,
    SelectInput,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        round: [value => (value !== undefined && value !== null && value !== '') || this.$t('lotto_game.alert1')],
        timeStart: [value => (value !== undefined && value !== null && value !== '') || this.$t('lotto_game.alert2')],
        timeEnd: [value => (value !== undefined && value !== null && value !== '') || this.$t('lotto_game.alert3')],
        rewardAmount: [
          value => (value !== undefined && value !== null) || this.$t('lotto_game.alert4'),
          value => /^\d+$/.test(value) || this.$t('lotto_game.alert5'),
        ],
        multiplyAmount: [
          value => (value !== undefined && value !== null) || this.$t('lotto_game.alert4'),
          value => /^[0-9]+$/.test(value) || this.$t('lotto_game.alert5'),
        ],
      },
      title: this.$t('lotto_game.activity_hint'),
      formData: {
        title: '',
        round: '',
        timeStart: '',
        timeEnd: '',
        typeBetUse: 'coin',
        betUse: 1,
        typeReward: 'money',
        rewardLastTwoDigits: null,
        multiplyTurnOverTwoDigits: 0,
        rewardLastThreeDigits: null,
        multiplyTurnOverThreeDigits: 0,
      },
      timeHourStart: moment().format('HH:mm:ss'),
      timeHourEnd: moment().format('HH:mm:ss'),
      minDate: moment().format('YYYY-MM-DD'),
      rewardTypes: ['point', 'coin', 'money'],

      breadcrumbsItems: [
        {
          text: this.$t('lotto_game.head'),
          disabled: false,
          href: '/predictLotto',
        },
        {
          text: this.$t('lotto_game.head2'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.formData.title = this.title
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'ทายหวย',
              url: window.location.href,
              detail: 'สร้าง ทายหวย',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    linkTo(name) {
      const id = this.$route.params
      this.$router.push({ name: name, params: { id } })
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: `${this.$t('lotto_game.confirm')}${this.formData.title}`,
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async action => {
            if (action.isConfirmed) {
              await this.addLottoData()
            }
          })
      }
    },
    async addLottoData() {
      this.formData.timeStart = `${this.formData.timeStart} ${this.timeHourStart}:00`
      this.formData.timeEnd = `${this.formData.timeEnd} ${this.timeHourEnd}:00`
      this.formData.multiplyTurnOverThreeDigits = parseInt(this.formData.multiplyTurnOverThreeDigits, 10)
      this.formData.multiplyTurnOverTwoDigits = parseInt(this.formData.multiplyTurnOverTwoDigits, 10)
      this.formData.rewardLastThreeDigits = parseInt(this.formData.rewardLastThreeDigits, 10)
      this.formData.rewardLastTwoDigits = parseInt(this.formData.rewardLastTwoDigits, 10)

      this.loading = true
      try {
        let res = await this.$store.dispatch('addLotto', this.formData)
        if (res.status === 'success') {
          this.$swal.fire(`${this.formData.title} สำเร็จ`, '', 'success').then(action => {
            this.$router.push({ name: 'predict-lotto' })
          })
        } else if (res.code === 400) {
          this.$swal.fire(`${this.$t('lotto_game.create_fail1_1')} ${this.formData.title} ${this.$t('lotto_game.create_fail1_2')}`, '', 'error')
        } else {
          this.$swal.fire(this.$t('lotto_game.create_fail2'), '', 'error')
        }
        // this.$refs.form.reset()
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }

      this.loading = false
    },
  },
  watch: {
    'formData.round': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.formData.title = this.title + newValue
      }
    },
  },
}
</script>
